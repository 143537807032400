import { useEffect, useState, useRef } from "react";
import { createWorker } from "tesseract.js";
import "font-awesome/css/font-awesome.min.css";
import ImageCrop from "./ImageCrop";
import TakePhoto from "./TakePhoto";
import logo from "./images/wejoin-logo.png";
import edit from "./images/svg/edit.svg";
import deleteIcon from "./images/svg/Delete_button.svg";
import ModalEdit from "./ModalEdit";
import { Skeleton, Typography, DialogActions, Button } from "@mui/material";

import UpdateModal from "./Update";
import { dataSendToAndroid } from "./DataSendAndroidIos";
import ReusableTable from "./ReusableTable";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import arrowback from "./images/arrowback.png";
import ReusableModal from "./ReusableModal";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  width: "75%",
  overflow: "auto",
  bgcolor: "white",
  color: "black",
  // border: "2px solid #000",
  boxShadow: 24,
  padding: "20px",
};
function DetectDevice() {
  const fileInputRef = useRef(null);

  const [ocr, setOcr] = useState("");
  const [imageData, setImageData] = useState(null);
  const [matchingArray, setMatchingArray] = useState([]);
  const [suggestedArray, setSuggestedArray] = useState([]);
  const [language, setLanguage] = useState("None");
  const [open, setOpen] = useState(false);
  const [array, setArray] = useState();

  // const [array, setArray] = useState({
  //   param1: "7053a808-c4b7-4b88-9707-47fe0a781ab1",
  //   param2: "fc4dcd1d-0b16-11ec-aa3f-ac1f6bb5693e",
  //   param3: "https://www.vconnectlive.com",
  // });
  const [showButton, setShowButton] = useState(false);
  const [loader, setLoader] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [onlySeeImage, setOnlySeeImage] = useState();
  const [firstcolumn, setFirstColumn] = useState({});
  const [columnChange, setColumnChange] = useState([]);
  const [finalArray, setFinalArray] = useState([]);
  const [imgSrc, setImgSrc] = useState("");
  const [newUi, setNewUi] = useState(false);
  const [showCase, setShowCase] = useState(true);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [updateData, setUpdateData] = useState([]);
  const [selectedIndices, setSelectedIndices] = useState([]); // Initialize selectedIndices with the index of the first row
  const [firstRowEdit, setFirstRowEdit] = useState();
  const [dispaly, setDisplay] = useState(true);
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const hiddenAnchorRef = useRef(null);
  const blobUrlRef = useRef("");
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  // const [handleToggle, setHandleToggle] = useState(false);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(16 / 9);
  const [formValue, setFormValue] = useState({
    total: "",
    scanned: "",
  });
  const webcamRef = useRef(null);
  const [photo, setPhoto] = useState(null);
  const handleResetStates = () => {
    // Reset useRef objects
    fileInputRef.current = null;
    webcamRef.current = null;
    previewCanvasRef.current = null;
    imgRef.current = null;
    hiddenAnchorRef.current = null;
    blobUrlRef.current = "";

    // Reset state variables
    setOcr("");
    setImageData(null);
    setMatchingArray([]);
    setSuggestedArray([]);
    setOpen(false);
    setArray(undefined);
    setShowButton(false);
    setLoader(false);
    setOnlySeeImage(undefined);
    setFirstColumn({});
    setColumnChange([]);
    setFinalArray([]);
    setImgSrc("");
    setDisplay(true);
    setNewUi(false);
    setShowCase(true);
    setCount(0);
    setLoading(false);
    setOpenUpdateModal(false);
    setUpdateData([]);
    setSelectedIndices([]);
    setFirstRowEdit(undefined);
    setFormValue({
      total: "",
      scanned: "",
    });
    setCrop(undefined);
    setCompletedCrop(undefined);
    setScale(1);
    setRotate(0);
    setAspect(16 / 9);
    setPhoto(null);
  };
  console.log("firstRowEdit", firstRowEdit);
  const capturePhoto = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    console.log("imageSrc", imageSrc);
    setPhoto(imageSrc);
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  useEffect(() => {
    // Parse the URL and get the value of the lang parameter
    const urlParams = new URLSearchParams(window.location.search);
    const lang = urlParams.get("lang");
    if (lang === "en") {
      setLanguage("eng");
    } else if (lang === "fr") {
      setLanguage("fra");
    } else if (lang === "ar") {
      setLanguage("arb");
    } else if (lang === "pt") {
      setLanguage("por");
    } else if (lang === "es") {
      setLanguage("spa");
    } else if (lang === "ru") {
      setLanguage("rus");
    }
  }, []); // Empty dependency array ensures that this effect runs only once, similar to componentDidMount()

  useEffect(() => {
    console.log("finalArrayssss", convertArrayToObjects(finalArray));
  }, [finalArray]);

  const removeColumn = (val) => {
    let boolean = columnChange.find((vale) => vale === val);
    console.log("valueans", boolean);
    return val !== boolean;
  };
  const handleColumn = (val) => {
    setColumnChange([...columnChange, val]);
  };
  const handleChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const formSubmit = (event) => {
    event.preventDefault();
    let payload = { total: formValue.total, scanned: formValue.scanned };
    fetch("https://analytics.wejoinlife.com/scaned_data", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.statusCode === 200) {
          window.location.reload();
        }
        console.log(responseJson);
      })
      .catch((error) => {
        console.error(error);
      });

    console.log("valuess", formValue);
  };
  const handleConfirm = () => {
    setConfirm(false);
  };
  const handleSendApiSingleSuggested = async (obj, index, state) => {
    console.log("objsujested", obj, index);
    setLoader(true);

    const payload = new URLSearchParams();
    payload.append("name", obj?.name);
    payload.append("sku", obj?.sku);
    payload.append("price", obj?.price);

    //   const response = await fetch(
    //     `https://www.vconnectlive.com/apiv2/ocr/search.jsp`,
    //     {
    //       method: "POST",
    //       body: payload.toString(),
    //       headers: {
    //         "Content-Type": "application/x-www-form-urlencoded",
    //         "api-key": "8f32a2d8-a84c-11eb-aa3f-ac1f6bb5693e",
    //         "auth-token": "0c4e13fa-6c30-4d88-b560-6498ef2a8b0e",
    //         "site-uid": "fc4dcd1d-0b16-11ec-aa3f-ac1f6bb5693e",
    //       },
    //     }
    //  );
    const response = await fetch(`${array?.param3}/apiv2/ocr/search.jsp`, {
      method: "POST",
      body: payload.toString(),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "api-key": "8f32a2d8-a84c-11eb-aa3f-ac1f6bb5693e",
        "auth-token": array?.param1,
        "site-uid": array?.param2,
      },
    });
    const result = await response.json();
    if (result?.suggested?.length > 0) {
      setOpenUpdateModal(true);
      let demyArray = [...result?.suggested];
      setCount(() => count + 1);
      console.log("updasuggestedtdeObjectss", result?.suggested);

      let newArray = { obj: obj, arr: [...result?.suggested], index: index };

      setUpdateData(newArray);
      setLoader(false);
    } else if (result?.matching?.length > 0) {
      setLoader(false);
      let demy = { previousData: obj, matching: result };
      console.log("updatdeObjectss", result?.matching);
      dataSendToAndroid(demy);
    } else if (state) {
      setLoader(false);
      setOpenUpdateModal(false);
    } else {
      setCount(() => count + 1);
      let demy = { newProduct: obj };
      dataSendToAndroid(demy);
      setLoader(false);
      setOpenUpdateModal(false);
    }
  };

  const handleSplit = (data) => {
    let stringsArray = data.split(" ");
    const concatenatedArray = [];
    let currentString = "";

    for (let i = 0; i < stringsArray.length; i++) {
      const element = stringsArray[i];

      if (isNaN(element.replace(",", "."))) {
        // Concatenate character strings with a space in between
        currentString += (currentString ? " " : "") + element;
      } else {
        // Push the accumulated character string, if any
        if (currentString !== "") {
          concatenatedArray.push(currentString);
          currentString = ""; // Reset the currentString for numbers
        }

        // Push numbers as they are
        concatenatedArray.push(element);
      }
    }

    // Push any remaining character string
    if (currentString !== "") {
      concatenatedArray.push(currentString);
    }
    //setArrLength(concatenatedArray.length);

    return concatenatedArray;
  };
  const checkIOSVersion = () => {
    const userAgent = navigator.userAgent;

    // Check if the user agent contains "iPhone" or "iPad" to identify iOS devices
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // Extract the iOS version using a regular expression
      const iOSVersion = parseFloat(
        (
          "" +
          (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/.exec(
            userAgent
          ) || [0, ""])[1]
        )
          .replace("_", ".")
          .replace(/_/g, "")
      );

      console.log("iOS Version:", iOSVersion);

      if (iOSVersion >= 16) {
        console.log("iOS version is greater than 16");
        setShowButton(false);
        // Perform actions specific to iOS version greater than 15.2
      } else {
        console.log("iOS version is not greater than 16");
        setShowButton(true);

        // Perform actions for other scenarios
      }
    } else {
      setShowButton(true);
      console.log("Not an iOS device");
      // Perform actions for non-iOS devices
    }
  };

  const handleSendApi = async () => {
    let cleanedData = [...finalArray];
    setLoader(true);

    const valueArray = convertArrayToObjects(finalArray);
    valueArray.forEach(item => {
      item.price = item.price.replace(',', '.');
  });
    let payload = {
      lang: "en",
      data: valueArray,
    };

    console.log("valueArray", valueArray);
    //  const response = await fetch(
    //   `https://www.vconnectlive.com/apiv2/ocr/searchall.jsp`,
    //   {
    //     method: "POST",
    //     body: JSON.stringify(payload),
    //     headers: {
    //       "Content-Type": "application/x-www-form-urlencoded",
    //       "api-key": "8f32a2d8-a84c-11eb-aa3f-ac1f6bb5693e",
    //       "auth-token": "0c4e13fa-6c30-4d88-b560-6498ef2a8b0e",
    //       "site-uid": "fc4dcd1d-0b16-11ec-aa3f-ac1f6bb5693e",
    //     },
    //   }
    // );

    const response = await fetch(`${array?.param3}/apiv2/ocr/searchall.jsp`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "api-key": "8f32a2d8-a84c-11eb-aa3f-ac1f6bb5693e",
        "auth-token": array?.param1,
        "site-uid": array?.param2,
      },
    });
    const result = await response.json();
    if (result?.matching?.length > 0) {
      setMatchingArray(result?.matching);
    }
    if (result?.suggested?.length > 0 || result?.not_found?.length > 0) {
      let arr = [...result?.suggested, ...result?.not_found];
      console.log("resultOverall", arr);
      setSuggestedArray(arr);
    }

    setNewUi(true);
    setLoader(false);
  };

  // const handleSendApi = async () => {
  //   const valueArray = convertArrayToObjects(finalArray);

  //   for (let index = 0; index < valueArray.length; index++) {
  //     const value = valueArray[index];
  //     console.log("answervalue", value);

  //     const payload = new URLSearchParams();

  //     // Iterate over the properties of the object and append them to the payload
  //     for (const key in value) {
  //       payload.append(key, value[key]);
  //     }

  //     try {
  //       const response = await fetch(
  //         "https://www.vconnectlive.com/apiv2/ocr/search.jsp",
  //         {
  //           method: "POST",
  //           body: payload.toString(),
  //           headers: {
  //           "Content-Type": "application/x-www-form-urlencoded",
  //           "api-key": "8f32a2d8-a84c-11eb-aa3f-ac1f6bb5693e",
  //           "auth-token": "0c4e13fa-6c30-4d88-b560-6498ef2a8b0e",
  //           "site-uid": "fc4dcd1d-0b16-11ec-aa3f-ac1f6bb5693e",
  //         },
  //         }
  //       );

  //       const result = await response.json();

  //       // Handle the result as needed
  //       if (result?.matching.length > 0 || result?.suggested.length > 0) {
  //         if (
  //           typeof finalArray[index][finalArray[index].length - 1] === "object"
  //         ) {
  //           console.log(
  //             "hmmmmm",
  //             finalArray[index][finalArray[index].length - 1]
  //           );
  //           finalArray[index].pop();
  //           finalArray[index].push(result);
  //           setFinalArray([...finalArray]);
  //         } else {
  //           finalArray[index].push(result);
  //           setFinalArray([...finalArray]);
  //         }
  //       } else {
  //         if (
  //           typeof finalArray[index][finalArray[index].length - 1] === "object"
  //         ) {
  //           finalArray[index].pop();
  //           setFinalArray([...finalArray]);
  //           alert("Data not found for item " + (index + 1));
  //         } else {
  //           alert("Data not found for item " + (index + 1));
  //         }
  //       }
  //       console.log("newresult", finalArray);
  //     } catch (error) {
  //       console.error("Error sending API request:", error.message);
  //     }
  //   }
  // };

  console.log("txtings", finalArray);

  function cleanData(arr) {
    return arr.filter(
      (item) => item !== "" && (Array.isArray(item) ? item?.length > 0 : true)
    );
  }
  function convertArrayToObjects(arr) {
    // Remove empty strings and empty arrays
    const cleanedData = arr.map(cleanData).filter((item) => item?.length > 0);

    console.log("cleanedData", cleanedData);

    const headers = cleanedData[0];
    const objectsArray = [];

    for (let i = 1; i < cleanedData.length; i++) {
      const obj = {};
      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = cleanedData[i][j];
      }
      objectsArray.push(obj);
    }
    console.log("objectsArray", objectsArray);

    return objectsArray;
  }
  function capitalizeFirst(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const convertImageToText = async () => {
    console.log("imageDatafinal", language);
    if (!imageData) return;
    setLoading(true);
    setImgSrc("");
    const worker = await createWorker({
      // langPath: './lang-data'
      // langPath: "./lang-data",
      // langPath: "https://scan.eleget.net/scan_data/",

      logger: (m) => {
        console.log(m);
      },
    });
    await worker.load();
    await worker.loadLanguage(language);
    await worker.initialize(language);
    // // Load Arabic language
    // await worker.loadLanguage("ara");
    // await worker.initialize("ara");

    const {
      data: { text },
    } = await worker.recognize(imageData);
    setLoading(false);
    setOcr(text);
    handleAllScenerio(text);
    // await worker.terminate();
  };
  console.log("finalObject", finalArray);
  const removeIndexFinalArray = (index) => {
    let demy = [...finalArray];
    let indexToRemove = index; // Index of the element you want to remove

    if (indexToRemove > -1 && indexToRemove < demy.length) {
      demy.splice(indexToRemove, 1);
      setFinalArray(demy);
      console.log("Element removed:", demy);
    } else {
      console.log("Index out of bounds.");
    }
  };
  const findLength=(array)=>{
    const nonEmptyLength = array.filter(item => item.trim() !== '').length;
    return nonEmptyLength

  }
  function handleAllScenerio(text) {
    const lines = text.split("\n");
    lines.forEach((item, index) => {
      const result=handleSplit(item);
      const lengthArray=findLength(result)

      if (index === 0) {
        console.log("findLength",findLength(result))
        if (lengthArray > 3) {
          setFinalArray((oldArray) => [...oldArray, result]);
        }
        else if(lengthArray===2)
        {
          let a=result.filter(item => item !== "");
          a.push("select")

          setFinalArray((oldArray) => [...oldArray, a]);

        }
        else if(lengthArray===1)
        {
          let a=result.filter(item => item !== "");
          a.push("select","select")


          setFinalArray((oldArray) => [...oldArray, a]);

        }
      } 
      else {
        setFinalArray((oldArray) => [...oldArray, result]);
      }
      console.log("handleSplit", result, index);
      // setFinalArray(...finalArray, [handleSplit(item)]);
    });
  }
  function isArrayOnlyEmptyStrings(arr) {
    // Use the every() method to check if every element in the array is an empty string.
    return arr?.every(function (element) {
      return element === "";
    });
  }
  function handleEditTable(arr, index) {
    const newArray = [...finalArray];

    // Step 2: Update the value in the copied array

    newArray[index] = ["ahmad", "khalil", "wardah"];
    setFinalArray(newArray);
    setOpen(true);
    console.log("arrayFinal", finalArray[index]);
    console.log("arrayindex", arr, index);
  }
  function handleEmptyArray(arr) {
    //console.log("yesss", arr[0] == "");
    if (isArrayOnlyEmptyStrings(arr)) {
      return null;
    } else {
      return arr;
    }
  }
  useEffect(() => {
    checkIOSVersion();
  }, [showButton]);
  useEffect(() => {
    convertImageToText();
  }, [imageData]);
  //   useEffect(() => {
  //     console.log("callediOS")
  //     if (window.Android) {
  //       // Call the receivedData function and capture the returned values
  //       var result = window.Android.recievedData();

  //       // Check if the result is an array or object with two values
  //       if (Array.isArray(result) && result.length === 2) {
  //           var value1 = result[0];
  //           var value2 = result[1];

  //           // Now you can use value1 and value2 as needed
  //           console.log("Value 1:", value1);
  //           sessionStorage.setItem("value1",value1)
  //           sessionStorage.setItem("value2",value2)

  //           console.log("Value 2:", value2);
  //       } else {
  //           console.error("Unexpected result from receivedData:", result);
  //       }
  //   } else {
  //       console.error("Android object not found in the window.");
  //   }
  //   if (window.webkit && window.webkit.messageHandlers.recievedData) {
  //     // Call the recievedData function and capture the returned values
  //     var resultIos = window.webkit.messageHandlers.recievedData.postMessage();

  //     // Check if the result is an array with two values
  //     if (Array.isArray(resultIos) && resultIos.length === 2) {
  //         var valueIos1 = resultIos[0];
  //         var valueIos2 = resultIos[1];

  //         // Now you can use value1 and value2 as needed
  //         console.log("Value 1:", valueIos1);
  //         console.log("Value 2:", valueIos2);
  //         sessionStorage.setItem("valueIos1",valueIos1)
  //         sessionStorage.setItem("valueIos2",valueIos2)
  //     } else {
  //         console.error("Unexpected result from updateData:", resultIos);
  //     }
  // } else {
  //     console.error("Webkit or updateData object not found in the window.");
  // }

  //   }, [])
  function handleImageChange(e) {
    const file = e.target.files[0];
    // console.log(file)
    if (!file) return;
    const reader = new FileReader();
    reader.onloadend = () => {
      const imageDataUri = reader.result;
      // console.log(imageDataUri)
      setImageData(imageDataUri);
    };
    reader.readAsDataURL(file);
  }

  // Function to render the recognized text in a table format with borders
  function renderTextInTable(text) {
    return (
      <>
        {text && !newUi ? (
          <>
            <div
              style={{ color: "#434b63", maxHeight: "75vh", overflow: "auto" }}
            >
              {finalArray.map((line, index) => (
                <div
                  style={{ display: !handleEmptyArray(line) && "none" }}
                  className="row"
                  key={index}
                >
                  {line.map((cell, cellIndex) => (
                    <>
                      {cell && removeColumn(cellIndex) && (
                        <>
                          {typeof cell == "object" ? (
                            <div style={{ display: "none" }}>
                              {cell?.matching?.length > 0 ? (
                                <button
                                  style={{
                                    color: "white",
                                    marginTop: "0px",
                                    borderRadius: "3px",
                                  }}
                                  onClick={() => dataSendToAndroid(cell)}
                                >
                                  Update
                                </button>
                              ) : (
                                <button
                                  style={{
                                    color: "white",
                                    marginTop: "0px",
                                    borderRadius: "3px",
                                    backgroundColor: "rgb(47, 63, 125)",
                                  }}
                                  // onClick={() => handleUpdate(cell)}
                                >
                                  Update
                                </button>
                              )}
                            </div>
                          ) : (
                            <div
                              key={cellIndex}
                              className={index === 0 && "headingTable"}
                              style={{
                                padding: "5px",
                                fontWeight:
                                  cellIndex === 1
                                    ? "700"
                                    : cellIndex === 2
                                    ? "400"
                                    : cellIndex === 0
                                    ? "700"
                                    : "400",
                                fontSize:
                                  cellIndex === 1
                                    ? "15px"
                                    : cellIndex === 2
                                    ? "15px"
                                    : "18px",
                                textAlign: "center",
                                color:
                                  cellIndex === 1
                                    ? "#20A1DE"
                                    : cellIndex === 2
                                    ? "#747474"
                                    : "#474747",
                              }}
                              onClick={() => {
                                index === 0 &&
                                  setFirstColumn({
                                    arr: line,
                                    index: index,
                                    cellIndex: cellIndex,
                                  });

                                // console.log("valuePass", line, index);
                              }}
                            >
                              {index === 0 ? capitalizeFirst(cell) : cell}
                              {index === 0 ? (
                                <span style={{ marginLeft: "10px" }}>
                                  <i
                                    style={{
                                      color:
                                        cellIndex === 1
                                          ? "#20A1DE"
                                          : cellIndex === 2
                                          ? "#747474"
                                          : "#474747",
                                      display: "contents",
                                    }}
                                    className="fa fa-caret-down"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              ) : null}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ))}

                  {!isArrayOnlyEmptyStrings(line) && (
                    <>
                      {index === 0 ? (
                        <div
                          className="headingTable"
                          style={{
                            padding: "5px",
                            fontSize: "15px",
                            textAlign: "center",
                          }}
                        >
                          <button
                            style={{
                              minWidth: "2rem",
                              background: "#20A1DE",
                              borderRadius: "3px",
                              border: "1px solid #20A1DE",
                              height: "28px",
                            }}
                            // className="buttons"
                            // onClick={() => {
                            //   setFirstColumn({ arr: line, index: index });

                            //   console.log("valuePass", line, index);
                            // }}
                          >
                            <img width="15px" src={edit} alt="logo" />
                          </button>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              marginTop: index === 0 ? "8px" : "5px",
                              textAlign: "center",
                              //display: index === 0 && "none",
                            }}
                          >
                            <button
                              style={{
                                minWidth: "2rem",
                                borderRadius: "3px",
                                height: "28px",
                                background: "#20A1DE",
                                border: "1px solid #20A1DE",
                              }}
                              // className="buttons"
                              onClick={() => {
                                setFirstColumn({ arr: line, index: index });

                                console.log("valuePass", line, index);
                              }}
                            >
                              <img width="15px" src={edit} alt="logo" />
                            </button>
                          </div>
                          <div
                            style={{
                              textAlign: "center",
                              //display: index === 0 && "none",
                            }}
                          >
                            <button
                              style={{
                                minWidth: "2rem",
                                height: "60px",
                                background: "#E41A4A",
                                borderTopRightRadius: "10px",
                                borderBottomRightRadius: "10px",
                                border: "1px solid #E41A4A",
                              }}
                              // className="buttons"
                              onClick={() => {
                                removeIndexFinalArray(index);

                                //   console.log("valuePass", line, index);
                              }}
                            >
                              <img width="15px" src={deleteIcon} alt="logo" />
                            </button>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
          </>
        ) : newUi ? (
          <>
            <ReusableTable
              handleSendApiSingleSuggested={handleSendApiSingleSuggested}
              suggestedArray={suggestedArray}
              setLoader={setLoader}
              array={array}
              count={count}
              matchingArray={matchingArray}
              selectedIndices={selectedIndices}
              setSelectedIndices={setSelectedIndices}
            />
          </>
        ) : null}
      </>
    );
  }

  return (
    <div className="Apps">
      {!imgSrc && (
        <div style={{ display: "none", padding: "10px" }}>
          {" "}
          <img width="80px" src={logo} alt="logo" />
        </div>
      )}
      <>
        <ImageCrop
          previewCanvasRef={previewCanvasRef}
          imgRef={imgRef}
          language={language}
          setLanguage={setLanguage}
          hiddenAnchorRef={hiddenAnchorRef}
          blobUrlRef={blobUrlRef}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          aspect={aspect}
          setAspect={setAspect}
          setCompletedCrop={setCompletedCrop}
          setImgSrc={setImgSrc}
          setArray={setArray}
          dispaly={dispaly}
          setDisplay={setDisplay}
          imgSrc={imgSrc}
          handleButtonClick={handleButtonClick}
          setShowCase={setShowCase}
          showCase={showCase}
          fileInputRef={fileInputRef}
          setImageData={setImageData}
          setOnlySeeImage={setOnlySeeImage}
          onlySeeImage={onlySeeImage}
          setOcr={setOcr}
          handleResetStates={handleResetStates}
        />
        {/* {showCase && (
          <>
            {showButton && (
              <TakePhoto
                setOnlySeeImage={setOnlySeeImage}
                onlySeeImage={onlySeeImage}
                setImgSrc={setImgSrc}
                setImageData={setImageData}
              />
            )}
          </>
        )} */}
        {/* <CreateForm /> */}
      </>

      {!ocr ? (
        <div style={{ marginBottom: "60px" }}>
          {loading && (
            <div>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((val, index) => (
                <div
                  key={val}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    justifyContent: "center",
                  }}
                >
                  {[1, 2, 3, 4].map((val) => (
                    <Typography key={val} component="div" variant="h4">
                      <Skeleton
                        style={{
                          marginLeft: val === 1 ? "15px" : "5px",
                          background: "#1ea0db",
                          marginRight: val === 4 ? "15px" : "",
                          textAlign: "center",
                        }}
                        width={val === 2 ? 60 : val === 4 ? 50 : 100}
                      />
                    </Typography>
                  ))}
                </div>
              ))}

              {/* {[1, 2].map((val, index) => (
                <div
                  key={val}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    
                    gap: "5px",
                    justifyContent: "center",
                    marginTop: index == 1 ? "-20px" : "20px",
                  }}
                >
                  <Typography component="div" variant="h4">
                    <Skeleton
                      style={{
                        marginLeft: "15px",
                        background: "#1ea0db",

                        marginTop: "14px",
                        textAlign: "center",
                      }}
                      width={150}
                    />
                  </Typography>
                  <Typography component="div" variant="h2">
                    <Skeleton
                      style={{
                        marginLeft: "5px",
                        background: "#1ea0db",
                        marginRight: "15px",
                      }}
                      width={180}
                    />
                  </Typography>
                </div>
              ))}
              <Typography component="div" variant="h2">
                <Skeleton
                  style={{
                    marginLeft: "25px",
                    marginRight: "25px",
                    background: "#1ea0db",
                  }}
                />
              </Typography> */}
            </div>
          )}
        </div>
      ) : (
        //<div>{loading && <div className="loader"></div>}</div>
        <>
          <div
            style={{
              overflow: "hidden",
              marginBottom: "auto",
              padding: "10px",
            }}
            className="display-flex"
          >
            {newUi ? (
              <div
                style={{
                  display: "flex",
                  marginLeft: "5px",
                  marginTop: "10px",
                  cursor: "pointer",
                }}
              >
                {/* <i
                style={{ fontSize: "20px" , color:"#434b63"}}
                class="fa fa-arrow-left"
                aria-hidden="true"
              ></i> */}
                <img
                  onClick={() => {
                    setNewUi(false);
                  }}
                  alt="arrowback"
                  onTouchStart={(event) =>
                    event.target.classList.add("touched")
                  }
                  onTouchEnd={(event) =>
                    event.target.classList.remove("touched")
                  }
                  src={arrowback}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",

                  marginTop: "10px",
                  marginLeft: "5px",
                }}
              >
                <img
                  onClick={handleResetStates}
                  onTouchStart={(event) =>
                    event.target.classList.add("touched")
                  }
                  onTouchEnd={(event) =>
                    event.target.classList.remove("touched")
                  }
                  src={arrowback}
                />
                {/* <i
                style={{ fontSize: "20px", color:"#434b63" }}
                class="fa fa-arrow-left"
                aria-hidden="true"
              ></i> */}
              </div>
            )}
            {!newUi && (
              <p className="headingNextAfterScan">
                Change the title and correct the scanned products in the below
                table.
              </p>
            )}

            <div
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
                padding: "5px",
                height: "95vh",
                overflow: "hidden",
              }}
            >
              {renderTextInTable(ocr)}
              {!newUi && (
                <button
                  className="all-buttons"
                  onClick={() => setConfirm(true)}
                >
                  Sync with server
                </button>
              )}
            </div>
            <ReusableModal
              style={style}
              open={confirm}
              handleClose={handleConfirm}
              cross={true}
            >
              <div>
                <p className="headingNext">Confirm the Title Correct</p>
              </div>
              <DialogActions style={{ padding: "0", marginTop: "24px" }}>
                <Button onClick={handleConfirm} color="primary">
                  Disagree
                </Button>
                <Button
                  onClick={() => {
                    handleSendApi();
                    handleConfirm();
                  }}
                  color="primary"
                  autoFocus
                >
                  Agree
                </Button>
              </DialogActions>
            </ReusableModal>

            {loader && <CircularProgressWithLabel />}
            <ModalEdit
              firstcolumn={firstcolumn}
              handleEditTable={handleEditTable}
              finalArray={finalArray}
              setFinalArray={setFinalArray}
              open={open}
              setOpen={setOpen}
            />
            {openUpdateModal && (
              <UpdateModal
                openUpdateModal={openUpdateModal}
                setSelectedIndices={setSelectedIndices}
                handleSendApiSingleSuggested={handleSendApiSingleSuggested}
                updateData={updateData}
                count={count}
                suggestedArray={suggestedArray}
                setOpenUpdateModal={setOpenUpdateModal}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default DetectDevice;
